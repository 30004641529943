/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState,useRef } from 'react';
import UserNavBar from "./UserNavBar.js"
import IconNavBar from "./IconNavBar.js"
import UserHeader from "./UserHeader.js"
import Button from '../../components/Button';
import { menuData } from "./data"
import { useNavigate, useLocation } from 'react-router';
import * as http from "../../services/http";
import * as app from "../../services/AppService";
import { useSelector, useDispatch } from "react-redux"
import Language from "../Language";
import ColorMode from "../ColorMode"
import { logout } from "../../store/auth"

export default function UserLayout(props) {

    const start = useRef(null)
    const goToStart = () => {
        setModel(old => ({
            ...old,
            hideStartBtn: true,
            hideEndBtn: false,
        }))
        start.current?.scrollIntoView({
            behavior: "smooth"
        })
    }
    const end = useRef(null)
    const goToEnd = () => {
        setModel(old => ({
            ...old,
            hideStartBtn: false,
            hideEndBtn: true,
        }))
        end.current?.scrollIntoView({
            behavior: "smooth"
        })
    }
    function CheckLogin() {
        http.get('/Login/CheckLogin', {}, function (res) {
            if (!res.Success) {
                window.location.href = "/";
            }
            else {
                if (res.AdditionalData < 1)
                    app.showAlert(app.translate("MsgSessionAfterSeconds"), "Warning", () => { window.location.href = "/" }, () => http.get("/Login/SetLang", { lang: app.translate("Lang") }))
                SetNavController(old => {
                    if (res.CarryOnData) {
                        var audio = new Audio('NewMessage.mp3');
                        audio.play();
                        if (Notification.permission === 'granted') {
                            var notification = new Notification(app.translate("MessageSystem"), {
                                icon: window.location.origin + "/Images/logo.png",
                                body: app.translate("YouhaveNewMessages")
                            });
                            notification.onclick = function () {
                                window.open(window.location.origin + "/MessageSystem");
                            };
                        }
                    }
                    return { ...old, msgCount: +res.Code }
                })
            }
        }, true);
    }

    useEffect(() => {
        CheckLogin();
        setInterval(function () {
            CheckLogin();
        }, 10000);
    }, []);

    const subNavigtion = useSelector(store => store.data.routs);
    const [navController, SetNavController] = React.useState({
        isMenuOpen: true,
        isProfileOpen: false,
        msgCount: 0
    })

    const path = useLocation().pathname;
    let navigate = useNavigate();

    let [model, setModel] = useState({
        menuArr: menuData.map(item => ({//احضار الاولاد المسموح فيهم فقط
            ...item,
            Child: item.Child.filter(sub => subNavigtion.find((row) => +row.parentId === +sub.id))
        })),
        navigation: [],
        subNavigtion,
        id: 0,
        hideStartBtn: true,
        hideEndBtn: true,
    });

    const dispatch = useDispatch()
    function Logout() {
        http.post("/Login/Logout", {}, (res) => {
            dispatch(logout());
            window.open("/", "_self");
        })
    }
    function preparePath(oldPath, ulrParam) {
        oldPath = oldPath + (ulrParam ? "/" + ulrParam : "");
        var arr = oldPath.split("/")
        if (arr.length > 3)
            arr.splice(-1)
        return arr.join('')
    }

    useEffect(() => {
        var son = subNavigtion.find(r => preparePath(r.path, r.ulrParam) === preparePath(path));
        if (son !== undefined) {
            var parent = menuData.map(row => row.Child.map(q => ({ ...q, parent: row.name }))).flat(1).find(r => r.id === son.parentId)
            if (parent !== undefined)
                setModel(old => ({ ...old, navigation: +parent.name === +son.name ? [parent.parent, parent.name] : [parent.parent, parent.name, son.name], id: son.parentId }))
        }
    }, [path])

    function FNclicked(node, evt) {
        setModel(old => ({ ...old, menuArr: old.menuArr.map(item => item.name === node.name ? { ...item, isActive: true } : { ...item, isActive: false }) }));
    }
    function SNclicked(Snode) {
        setModel(old => ({ ...old, id: Snode.id }));
        var firstWin = subNavigtion.find(r => +r.parentId === +Snode.id);
        if (firstWin)
            navigate(firstWin.path + (firstWin.ulrParam ? "/" + firstWin.ulrParam : ""));
    }
    function openPath(item) {
        if (model.navigation.length === 3)
            model.navigation.splice(-1)
        model.navigation.push(item.name)
        navigate(item.path + (item.ulrParam ? "/" + item.ulrParam : ""));
    }
    useEffect(() => {
        setTimeout(() => {
            var x = document.getElementById("crumblist")
            setModel(old => ({
                ...old,
                hideEndBtn: x.clientWidth >= x.scrollWidth,
                hideStartBtn: true,
            }))
        }, 1000)
    }, [model.id])
    return (
        <div>
            <UserHeader profileButtonClicked={() => SetNavController(old => ({ ...old, isProfileOpen: !old.isProfileOpen }))}
                menuButtonClicked={() => SetNavController(old => ({ ...old, isMenuOpen: !old.isMenuOpen }))}
                msgCount={navController.msgCount} />

            <main className="d-xl-flex flex-xl-nowrap w-100 overflow-auto bg-body">

                <div className="text-bg-dark d-none d-xl-block sticky-xl-top">
                    <IconNavBar />
                </div>

                <div className="bd-sidebar sticky-xl-top" style={{ 'display': (navController.isMenuOpen ? 'block' : 'none') }}>
                    <UserNavBar FNclicked={FNclicked} MenuArr={model.menuArr.filter(row => row.Child.length > 0)} SNclicked={SNclicked} />
                </div>

                <div className={`bd-sidebar sticky-lg-top d-${(navController.isProfileOpen ? 'block ' : 'none')} `}>
                    <div className="offcanvas offcanvas-end show sticky-lg-top bg-primary z-1 pt-6" tabIndex="-1" id="bdSidebar" aria-labelledby="bdSidebarOffcanvasLabel">
                        <div className="offcanvas-body ">
                            <ul className="navbar-nav flex-row flex-wrap bd-navbar-nav">
                                <li className="nav-item col-6 col-lg-auto">
                                    <div className="nav-link fs-4 text-center">
                                        <span className="text-light">{app.translate("username")}</span>
                                    </div>
                                    <div className="nav-link">
                                        <Language TitleClass="text-light fs-4" />
                                    </div>
                                </li>

                                <li className="nav-item col-6 col-lg-auto">
                                    <div className="nav-link fs-4 text-center">
                                        <span className="text-light">{app.options().userName}</span>
                                    </div>
                                    <div className="nav-link">
                                        <ColorMode TitleClass="text-light fs-4" />
                                    </div>
                                </li>
                                <li className="nav-item col-12 col-lg-auto">
                                    <Button Class="btn-light w-100 text-primary-emphasis" Label="Signout" Tooltip="Signout" Clicked={Logout} Icon="off fw-bold"></Button>
                                </li>
                            </ul>
                            <hr className="d-lg-none text-white-50" />
                        </div>
                    </div>
                </div>

                <div className="card bg-body border-0 container-fluid">
                    <div className=" pt-2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                {
                                    model.navigation.map((nav, index) => <li className="h5 text-primary-emphasis fw-bold breadcrumb-item" key={index}>{app.translate(nav)}</li>)
                                }
                            </ol>
                        </nav>
                        <nav className="breadcrumb-divider-none w-100 position-relative d-inline-block overflow-hidden " aria-label="breadcrumb">
                            <ol className="breadcrumb d-inline-block text-nowrap mb-0" id="crumblist">
                                <li ref={start} className="breadcrumb-item d-inline-block"></li>
                                {!model.hideStartBtn &&
                                    <li className="breadcrumb-item position-sticky start-0 d-inline-block bg-white " key="900" >
                                        <Button Class="btn-light border shadow-sm" Icon={`${app.translate("Lang") === "ar" ? "right" : "left"}-arrow-light`} Clicked={goToStart} />
                                    </li>
                                }
                                {model.subNavigtion.filter(item => +item.parentId === +model.id)
                                    .map((nav, index) =>
                                        <li className="breadcrumb-item d-inline-block " key={index}  >
                                            <button type="button" onClick={() => openPath(nav)} className="btn btn-link px-1">
                                                {app.translate(nav.name)}</button>
                                        </li>)
                                }

                                {!model.hideEndBtn &&
                                    <li className="breadcrumb-item position-sticky end-0 d-inline-block bg-white " key="901" >
                                        <Button Class="btn-light border shadow-sm " Icon={`${app.translate("Lang") === "ar" ? "left" : "right"}-arrow-light`} Clicked={goToEnd} />
                                    </li>
                                }
                                <li ref={end} className="breadcrumb-item d-inline-block"></li>
                            </ol>
                        </nav>
                    </div>

                    <div>
                        {props.children}
                    </div>
                </div>
            </main>
        </div>
    );
}